import { render, staticRenderFns } from "./AntiSanctionList.vue?vue&type=template&id=17f29cf4&scoped=true&"
import script from "./AntiSanctionList.vue?vue&type=script&lang=js&"
export * from "./AntiSanctionList.vue?vue&type=script&lang=js&"
import style0 from "./AntiSanctionList.vue?vue&type=style&index=0&id=17f29cf4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17f29cf4",
  null
  
)

export default component.exports