<template>
  <div class="w-100 mt-10">
    <div class="d-block md:flex justify-between">

    </div>
    <vs-card style="flex-direction: column-reverse" class="shadow-lg mr-2 p-1 lg:w-12/12 md:w-12/12 sm:w-12/12 sm:flex-col  rounded-lg shadow-sm bg-h-primary-light light-border">
      <div style="flex-direction: column" class="flex lg:flex-row sm:flex-column md:flex-column ">
        <div class="w-100 flex justify-content-start flex-col lg:ml-5 ">
          <div class="flex my-3 justify-content-between items-center ">
            <div>
              <vs-button to="/anti/sanction/create" class="cursor-pointer btn bg-h-secondary border-0 btn-secondary  rounded text-sm md:text-base py-1 mb-2">ساخت ضد تحریم جدید</vs-button>
              &nbsp; <a target="_blank" class="cursor-pointer btn bg-h-secondary border-0 btn-secondary rounded text-sm md:text-base py-1 mb-2 text-white" href="https://haiocloud.com/%d8%a2%d9%85%d9%88%d8%b2%d8%b4-%d8%a7%d8%b3%d8%aa%d9%81%d8%a7%d8%af%d9%87-%d8%a7%d8%b2-%d8%aa%d8%ad%d8%b1%db%8c%d9%85-%d8%b4%da%a9%d9%86/">راهنمای کار با ضد تحریم</a>
            </div>
          </div>
          <br />
          <div class="mt-5">
            <div style="width: 100%">
              <div v-if="antiSantionList == '' " class="text-center">
                <span class="text-lg mb-4 block">شما درحال حاضر ضد تحریم ندارید</span>
                <div>
                  <vs-button to="/anti/sanction/create" class="cursor-pointer btn bg-h-secondary border-0 btn-secondary  rounded text-sm md:text-base py-1 mb-2">ساخت ضد تحریم جدید</vs-button>
                </div>
              </div>
              <div
                v-for="antiSantionItem in antiSantionList"
                :key="antiSantionItem.id"
                class="row bg-h-primary mx-3 mb-2 rounded-lg p-3 items-center justify-between md:justify-center">

                <div class="flex justify-start items-center w-full lg:w-1/3">
                  <div class="flex gap-4 items-center w-1/12 lg:w-1/12">
                    <div v-if="antiSantionItem.is_over === 0">
                      <div class="bg-h-success rounded-full w-4 h-4"></div>
                    </div>
                    <div v-else-if="antiSantionItem.is_over === 1">
                      <div class="bg-h-danger rounded-full w-4 h-4"></div>
                    </div>
                  </div>

                  <div class="w-1/2 text-right" style="text-align: right !important;" >
                    <router-link class="text-right text-sm md:text-lg text-white ir-yekan-bold cursor-pointer" :to="`/anti/sanction/details/${antiSantionItem.id}`" >{{antiSantionItem.title}}</router-link>
                  </div>


                </div>
                <div class="flex flex-wrap justify-center lg:justify-end md:mt-4 lg:mt-0 items-center gap-4 w-full md:w-full lg:w-2/3">

                  <div class="flex justify-center gap-2 items-center w-32">
                    <div class="bg-h-primary-light rounded p-2 w-full text-center text-sm cursor-pointer">
                      {{ antiSantionItem.expired_at }}
                      <span class="text-xs text-white d-block text-center">تاریخ پایان</span>
                    </div>
                  </div>

                  <div class="flex justify-center gap-2 items-center w-32">
                    <div class="bg-h-primary-light rounded p-2 w-full text-center text-sm cursor-pointer">
                      {{ antiSantionItem.usage_percent }}
                      <span class="text-xs text-white d-block text-center">درصد مصرف</span>
                    </div>
                  </div>

                  <div class="flex justify-center gap-2 items-center w-32">
                    <div class="bg-h-primary-light rounded p-2 w-full text-center text-sm cursor-pointer" @click="copyToClipboard('wget -O setup.sh http://tools.haiocloud.com/haiogateway.sh && bash setup.sh')">
                       کامند نصب
                      <span class="text-xs text-white d-block text-center">Setup</span>
                    </div>
                  </div>

                  <div class="flex justify-center gap-2 items-center w-32">
                    <div class="bg-h-primary-light rounded p-2 w-full text-center text-sm cursor-pointer" @click="copyToClipboard(antiSantionItem.config)">
                       Qv2ray
                      <span class="text-xs text-white d-block text-center">کانفیگ</span>
                    </div>
                  </div>

                  <div class="flex justify-center gap-2 items-center w-32">
                    <div class="bg-h-primary-light rounded p-2 w-full text-center text-sm cursor-pointer" @click="copyToClipboard(antiSantionItem.password), antiSantionItem.PasswdBlur = !antiSantionItem.PasswdBlur" :class="{blurtext : antiSantionItem.PasswdBlur}">
                      {{ antiSantionItem.password }}
                      <span class="text-xs text-white d-block text-center">کلمه عبور</span>
                    </div>
                  </div>

                  <router-link
                    :id="'details' + antiSantionItem.id"
                    class="hover:shadow-xl m-1 text-grey cursor-pointer"
                    :to="`/anti/sanction/details/${antiSantionItem.id}`">
                    <b-tooltip variant="info" :target="'details' + antiSantionItem.id" triggers="hover">
                      مشخصات
                    </b-tooltip>
                    <b-icon class="my-2" scale="2" icon="arrow-left-square" />
                  </router-link>

                </div>
              </div>


            </div>
            <vs-pagination
              class="center w-full"
              :total="countPage"
              v-model="pageNumber"
            />
          </div>
        </div>
      </div>

      <!-- New Service -->
      <!-- Reboot VM -->
      <vs-popup class="holamundo"  title="ری استارت کردن سرور" :active.sync="popupActiveReset">
        <div class="flex justify-content-center items-center flex-col">
          <div>
            <img width="200" src="../../../../assets/images/elements/cloud-outline.webp" alt="">
          </div>
          <div>
            آیا از ری استارت کردن سرور خود مطمئن هستید ؟
          </div>
          <div class="flex justify-content-between mt-4">
            <vs-button color="primary" @click="setRebootPrompt(id)" class="rounded">بله </vs-button>
          </div>
        </div>
      </vs-popup>
      <!-- ShutDown VM -->
      <vs-popup :active.sync="shutdownPrompt" title=" خاموش کردن">
        <div class="p-2">
          <h5>آیا مایل به خاموش کردن ابر هستید؟</h5>
          <vs-button icon="power" class="rounded float-right" @click="turnOff(id)"
          >خاموش کردن</vs-button
          >
        </div>
      </vs-popup>
      <!-- delete vira cloud prompt -->
      <div>
        <vs-popup :active.sync="deletePrompt" title="حذف  ابر">
          <div class="con-exemple-prompt">
            <div>
              <p>آیا از حذف اطمینان دارید. ؟</p>
              <span class="text-danger">نکته مهم:</span> <span>حداقل هزینه جهت ساخت مجدد سرور 20 هزار تومان است، دقت داشته باشید بعد از حذف سرور باید حداقل شارژ را برای ساخت مجدد داشته باشید.</span>
            </div>
            <vs-button
              :disabled="isDeleting"
              icon="delete"
              class="float-right rounded w-25 mt-4"
              color="danger"
              @click="deleteRecord(id)"
            >تایید حذف</vs-button>
          </div>
        </vs-popup>
      </div><!-- reboot vm prompt -->
    </vs-card>
    <!--  prompt area-->

    <!--end  prompt area-->
  </div>
</template>

<script>
import CountTo from 'vue-count-to'

export default {
  name: 'AntiSantionList',
  components: {
    CountTo
  },
  data () {
    return {
      popupActiveReset: false,
      shutdownPrompt: false,
      pageNumber: 0,
      search: '',
      deletePrompt: false,
      isDeleting: false,
      loading:false,
      activePrompt : false,
      selectedID: 0,
      antiSantionList: [],
      flag: '',
      countPage: 1,
      serverIdreboot: null,
      LoginToVDIPopup: false
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    },
    activeUser () {
      return this.$store.state.AppActiveUser
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  watch: {
    pageNumber () {
      this.getAntiSantionList()
    },
    $route () {
      this.getAntiSantionList()
    },
    search () {
      this.getAntiSantionList()
    }
  },
  methods: {
    copyToClipboard (p) {
      navigator.clipboard.writeText(p).then(() => {
        this.$vs.notify({ text: ' کپی شد', color: 'success' })
      })
    },
    deleteRecord (id) {
      this.isDeleting = true
      this.$http
        .delete(`/cloud/desktop/${id}`, {
          confirm_code: this.requestCode
        })
        .then((res) => {
          this.getAntiSantionList()
          this.$vs.notify({
            color: 'warning',
            text: res.data.message,
            title: 'حذف ویرا ابر'
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message,
            text: 'حذف ویرا ابر'
          })
        })
        .finally(() => {
          this.getAntiSantionList()
          this.deletePrompt = false
          this.isDeleting = false
        })
    },
    setDeletePrompt (d) {
      this.selectedID = d
      this.activePrompt = false
      this.deletePrompt = true
    },
    setRebootPrompt (d) {
      this.reboot(d)
      this.popupActiveReset = false
    },
    turnOff (id) {
      this.$http
        .get(`/cloud/desktop/${id}/shutdown`)
        .then((res) => {
          this.shutdownPrompt = false
          this.vdi = false
          this.getAntiSantionList()
          this.$vs.notify({
            color: 'success',
            title: ' ',
            text: res.data.message
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: '  ',
            text: err.response.data.message
          })
        })
    },
    reboot (id) {
      this.rebootPrompt = false
      this.$http
        .get(`/cloud/desktop/${id}/reboot`)
        .then((res) => {
          this.vdi = false
          this.getAntiSantionList()
          this.$vs.notify({
            color: 'success',
            title: ' ',
            text: res.data.message
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: '  ',
            text: err.response.data.message
          })
        })
    },
    getAntiSantionList () {
      this.$vs.loading()
      this.$http
        .get(
          `/anti/sanction/?page=${this.pageNumber}&filter=${this.search}`
        )
        .then((res) => {
          this.$vs.loading.close()
          const d = res.data.params
          this.countPage = d.last_page
          this.antiSantionList = d.data.map(item => {
            item.PasswdBlur = true
            return item
          })
        })
        .catch((err) => {
          this.$vs.loading.close()
          if (err.response.status === 404) {
            this.antiSantionList = []
            return
          }

          this.$vs.notify({
            color: 'danger',
            title: ' ویرا کلود',
            text: err.response.data.message
          })
        })
    },
    getFlag (status) {
      if (status === 1) {
        this.repeat = setTimeout(() => {
          this.getAntiSantionList()
        }, 60000)
      }
    },
    openNewPage (link) {
      window.open(link, '_block')
    },
    getVDILink (id) {
      this.$http.get(`/cloud/desktop/${id}/login`)
        .then(response => {
          this.VDILink = response.data.params.vdi_url
          this.openNewPage(this.VDILink)
        })
        .catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color: 'danger'
          })
        })
    }

  },
  async mounted () {
    await this.getAntiSantionList()
    await this.getFlag()
  },
  destroyed () {
    window.clearInterval(this.repeat)
  }
}
</script>

<style scoped>
.bg-box {
  background-color: #e9f1fa !important;
}
.white-light {
  background-color: #ffffff !important;
  border-radius: 1rem;
  filter: drop-shadow(5px 5px 10px #98befb);
}
.hoverItem:hover {
  transition: 0.2s;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}

.blurtext {
  color: transparent;
  text-shadow: 0 0 8px #f1f1f1;
}

</style>
